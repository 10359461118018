define("bottomline-execution/serializers/trip-stop-storage", ["exports", "ember-data", "active-model-adapter", "@ember/utils"], function (_exports, _emberData, _activeModelAdapter, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      storage: {
        embedded: 'always'
      },
      confirmed15Qty: {
        key: 'Confirmed15Qty'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      switch (options.purpose) {
        case 'persistPredip':
          return this._serializeToPersistPredip(snapshot);

        case 'forReport':
          return this.forReport(snapshot);

        default:
          return this.forServer(snapshot);
      }
    },
    _serializeToPersistPredip: function _serializeToPersistPredip(snapshot) {
      return {
        trip_stop_storage_id: snapshot.id,
        start_inventory_qty: snapshot.attr('startInventoryQty')
      };
    },
    forReport: function forReport(snapshot) {
      var customAttributes = {
        id: snapshot.id,
        material: this._material(snapshot),
        materialID: snapshot.attr('MaterialID'),
        storage: this._storage(snapshot)
      };
      return Object.assign({}, snapshot.attributes(), customAttributes);
    },
    _material: function _material(snapshot) {
      var material = snapshot.record.get('material');

      if ((0, _utils.isPresent)(material)) {
        return material.serialize({
          purpose: 'forReport'
        });
      } else {
        return {};
      }
    },
    _storage: function _storage(snapshot) {
      var storage = snapshot.belongsTo('storage');

      if ((0, _utils.isPresent)(storage)) {
        return storage.serialize({
          purpose: 'forReport'
        });
      } else {
        return {};
      }
    },
    forServer: function forServer(snapshot) {
      return {
        id: snapshot.id,
        confirmed15Qty: snapshot.attr('confirmed15Qty'),
        confirmedQty: this._confirmedQtyWithSign(snapshot),
        confirmedWeight: snapshot.attr('confirmedWeight'),
        Density: snapshot.attr('Density'),
        DropCount: snapshot.attr('DropCount'),
        endInventoryQty: snapshot.attr('endInventoryQty'),
        MaterialID: snapshot.attr('MaterialID'),
        meter15Qty: snapshot.attr('meter15Qty'),
        MeterDeliveryInfo: snapshot.attr('MeterDeliveryInfo'),
        meterQty: snapshot.attr('meterQty'),
        meterWeight: snapshot.attr('meterWeight'),
        plannedQty: snapshot.attr('plannedQty'),
        startInventoryQty: snapshot.attr('startInventoryQty'),
        startInventoryQtyPercentage: snapshot.record.get('startInventoryQtyPercentage'),
        StorageID: snapshot.attr('StorageID'),
        TankFilledYN: snapshot.attr('TankFilledYN'),
        Temperature: snapshot.attr('Temperature'),
        TicketNb: snapshot.attr('TicketNb'),
        TripID: snapshot.attr('TripID'),
        TripStopID: snapshot.attr('TripStopID'),
        PreDeliveryPct: snapshot.attr('PreDeliveryPct'),
        PreDeliveryPressure: snapshot.attr('PreDeliveryPressure'),
        PreDeliveryTemp: snapshot.attr('PreDeliveryTemp'),
        PostDeliveryPct: snapshot.attr('PostDeliveryPct'),
        PostDeliveryPressure: snapshot.attr('PostDeliveryPressure'),
        PostDeliveryTemp: snapshot.attr('PostDeliveryTemp')
      };
    },
    _confirmedQtyWithSign: function _confirmedQtyWithSign(snapshot) {
      if (snapshot.record.get('hasReturnableMaterial')) {
        return -1 * snapshot.attr('confirmedQty');
      }

      return snapshot.attr('confirmedQty');
    }
  });

  _exports.default = _default;
});