define("bottomline-execution/templates/components/return-materials", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yfUGB5z7",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"column.ReturnableMaterial\"]]]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"column.ConfirmedReturnQty\"]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"returnableMaterials\"]]],null]],null],null,[[[1,\"    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,[30,1,[\"material\",\"MaterialNameShort\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n          \"],[1,[28,[35,3],null,[[\"numericValue\"],[[30,1,[\"confirmedQty\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"returnableMaterial\"],false,[\"editable-t\",\"each\",\"-track-array\",\"input-number\"]]",
    "moduleName": "bottomline-execution/templates/components/return-materials.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});